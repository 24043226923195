import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { HttpModule } from '@angular/http';
import { ActivatedRoute, Router }       from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment }                  from '../../environments/environment';
import { SignIn }                       from '../models/signin';
import { Auth }                         from '../models/auth';
import { AppService, HelperService } from '../services/index';
import { StorageService } from './../services/storage.service';
import { BaseComponent } from '../base.component';
import { WindowRef } from '../shared/windowRef';
import { WeekCommencing } from '../models/weekCommencing';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends BaseComponent {
   weekCommencing: any = [];
   statusMessage: string;
   contactEmail: string;
   constructor(
		private windowRef: WindowRef,
		protected route: ActivatedRoute,
    private _router: Router,
    private appService: AppService,
    private helperService: HelperService,
    private storageService: StorageService
  ) {
    super();
  }
 
  ngOnInit() {
    super.ngOnInit();
    this.getWeeks();
    
    this.contactEmail = environment.mockURL.indexOf('.uk') > 1 ? 'accounts@thecreativestore.uk' : (environment.mockURL.indexOf('com.au') > 1 ? 'accounts@thecreativestore.com.au'
                      : 'accounts@thecreativestore.co.nz');
  } 

  getWeeks() {
    this.appService.GetWeeksCommencing()
            	.then(response => {
                if (response) {
                  this.weekCommencing = response;
                }
            	}).catch(err => {this.helperService.setShowModal(err); });
  }
}
